(function (angular, undefined) {
    "use strict";
    var core = angular.module("mikesCore");

    core.config(["$stateProvider", "$urlRouterProvider", "$mdThemingProvider",
        function ($stateProvider, $urlRouterProvider, $mdThemingProvider) {
        $mdThemingProvider.theme("default")
            .primaryPalette("indigo")
            .accentPalette("grey")
            .warnPalette("deep-orange");

        // For all unmatched url, redirect to /
        $urlRouterProvider.otherwise("/mike's/blog");

        $stateProvider
            .state("mike's", {
                url: "/mike's",
                abstract: true,
                views: {
                    "": {
                        templateUrl: "app/core/templates/main.html",
                        controller: "mikesCore.ctrl",
                        controllerAs: "vm"
                    },
                    "navbar@mike's": {
                        templateUrl: "app/core/templates/navbar.html",
                        controller: "mikesCore.navbar.ctrl",
                        controllerAs: "vm"
                    },
                    "tabs@mike's": {
                        templateUrl: "app/core/templates/nav-tabs.html",
                        controller: "mikesCore.navtabs.ctrl",
                        controllerAs: "vm"
                    },
                    "sidenav@mike's": {
                        templateUrl: "app/core/templates/sidenav.html",
                        controller: "mikesCore.sidenav.ctrl",
                        controllerAs: "vm"
                    }
                }
            })
            .state("about", {
                parent: "mike's",
                url: "/about",
                data: {
                    pageTitle: "About"
                },
                views: {
                    "": {
                        templateUrl: "app/core/templates/asset.html",
                        controller: "mikesCore.asset.ctrl",
                        controllerAs: "vm"
                    }
                }
            })
            .state("privacy", {
                parent: "mike's",
                url: "/privacy",
                data: {
                    pageTitle: "Privacy Policy"
                },
                views: {
                    "": {
                        templateUrl: "app/core/templates/asset.html",
                        controller: "mikesCore.asset.ctrl",
                        controllerAs: "vm"
                    }
                }
            })
            .state("license", {
                parent: "mike's",
                url: "/license",
                data: {
                    pageTitle: "License"
                },
                views: {
                    "": {
                        templateUrl: "app/core/templates/asset.html",
                        controller: "mikesCore.asset.ctrl",
                        controllerAs: "vm"
                    }
                }
            });
    }]);
})(angular);
